import { template as template_2db266e39e214d1e8fd23b2de7d3f3f9 } from "@ember/template-compiler";
const WelcomeHeader = template_2db266e39e214d1e8fd23b2de7d3f3f9(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
