import { template as template_50c77b32f0dd43fd88b5bb433769b543 } from "@ember/template-compiler";
const FKText = template_50c77b32f0dd43fd88b5bb433769b543(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
