import { template as template_a0bbcbe76f7f4d1cabc7b63dc7288a70 } from "@ember/template-compiler";
const FKLabel = template_a0bbcbe76f7f4d1cabc7b63dc7288a70(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
