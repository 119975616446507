import { template as template_2c53e8027b7d41beaf2b70444209e35b } from "@ember/template-compiler";
const SidebarSectionMessage = template_2c53e8027b7d41beaf2b70444209e35b(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
