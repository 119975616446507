import { template as template_89ea2e1d6fd14dc499b5df5507003b56 } from "@ember/template-compiler";
const FKControlMenuContainer = template_89ea2e1d6fd14dc499b5df5507003b56(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
